<template>
  <app-card-wizard title="Sirküler Bildirim Ayarları">
    <template #description>
      <p>
        Bu adımda TİM ya da Bağlı birliklerden gönderilecek sirküler
        gönderilecek e-posta adresleri ile ilgili tanımlamaları yapmanız
        gerekmektedir. Aşağıdaki listede her bir adımda belirttiğiniz e-posta
        adreslerinin listesi yer almaktadır. Lütfen bu listede belirtilen her
        bir e-posta adresi için sirküler almak isteyip istemediğinizi belirtin.
        Bu listede yer alan e-posta adreslerinden başka adreslere de sirküler
        gönderilmesini istiyorsanız diğer adresleri de bu adımda listeye
        ekleyebilirsiniz.
      </p>
    </template>
    <section class="form-section">
      <table class="table table-clean">
        <thead>
          <th scope="col"><label>E-posta Adresi</label></th>
          <th scope="col" class="c-sirkuler">
            <label>Sirküler Gönderimi</label>
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
        </thead>
        <tbody>
          <tr v-for="(item, key) in emails" :key="key">
            <td>
              <app-form-group
                name="email"
                :class="{ 'form-group--error': !item.isValid }"
              >
                <input
                  :readonly="item.readOnly"
                  name="email"
                  class="form-control"
                  placeholder="E-posta Adresi"
                  v-model.trim="item.email"
                  @change="isValid"
                />
                <div class="field-validation-error" v-if="!item.isValid">
                  <div v-if="item.duplicate">
                    Bu e-posta adresi zaten listede yer alıyor
                  </div>
                  <div v-else>Geçerli bir e-posta adresi girin</div>
                </div>
              </app-form-group>
            </td>
            <td class="c-sirkuler">
              <p-dropdown
                v-model="item.sirkuler"
                :options="yesNo"
                optionLabel="name"
                optionValue="value"
                style="width:100%;"
                :disabled="!item.readOnly"
                @change="updateNotificationSettings"
              ></p-dropdown>
            </td>
            <td class="c-remove">
              <button
                class="btn btn-outline-secondary"
                :disabled="item.readOnly"
                @click="removeEmail(key)"
              >
                <i class="far fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <button type="button" @click="addNewEmail" class="btn btn-clean">
        <i class="far fa-plus"></i> Yeni E-posta Adresi Ekle
      </button>
    </section>
    <app-wizard-buttons
      :next-button-disabled="isLoading"
      :next-button-is-loading="isLoading"
      :next-button-text="nextButtonText"
      @next-button-click="onSubmit"
      :prev-button-visible="prevButtonVisible"
      :prev-button-disabled="isLoading"
      @prev-button-click="goTo(-1)"
    ></app-wizard-buttons>
  </app-card-wizard>
</template>

<script>
//import { required, minLength } from "@vuelidate/validators";
//import { settings } from "../shared";
import { mapGetters } from "vuex";
import { isEmail } from "../shared";

export default {
  data() {
    return {
      yesNo: [
        {
          name: "Sirküler İstiyorum",
          value: true
        },
        {
          name: "Sirküler İstemiyorum",
          value: false
        }
      ],
      isLoading: false,
      emails: []
    };
  },
  methods: {
    isValid() {
      const firstInvalidEmail = this.emails.findIndex(
        (e) => !e.readOnly && !isEmail(e.email)
      );

      if (firstInvalidEmail > 0) {
        this.emails[firstInvalidEmail].isValid = false;
        return false;
      }

      for (let i = 0; i < this.emails.length; i++) {
        this.emails[i].isValid = true;
        this.emails[i].duplicate = false;
      }

      for (let i = this.emails.length - 1; i >= 0; i--) {
        const hasDuplicate =
          this.emails.findIndex((e) => e.email == this.emails[i].email) != i;
        if (hasDuplicate) {
          this.emails[i].isValid = false;
          this.emails[i].duplicate = true;
          return false;
        }
      }

      this.updateNotificationSettings();

      return true;
    },
    onSubmit() {
      if (!this.isValid()) {
        this.scrollToError();
        return;
      }

      this.isLoading = true;

      setTimeout(() => {
        this.updateNotificationSettings();
        this.markStepAsCompleted(this.stepName);
        this.isLoading = false;

        this.goTo("/review/" + this.applicationData.stateId);
      }, 500);
    },
    updateNotificationSettings() {
      this.updateApplicationData({ notificationSettings: this.emails });
    },
    addNewEmail() {
      if (!this.isValid()) {
        return;
      }

      this.emails.push({
        email: "",
        readOnly: false,
        sirkuler: true,
        notifications: true,
        isValid: true
      });
    },
    removeEmail(index) {
      console.log(index);
      this.emails.splice(index, 1);
      this.updateNotificationSettings();
      //this.emails.remove(index);
    }
  },
  computed: {
    prevButtonVisible() {
      return !this.applicationData.fromReview;
    },
    nextButtonText() {
      return this.applicationData.fromReview ? "Kaydet" : "Devam Et";
    },
    stepName() {
      return "notification-settings";
    },
    validator() {
      return this.$v;
    },
    ...mapGetters({
      applicationData: "getApplicationData"
    })
  },
  beforeMount() {
    let emails = [];
    const ad = this.applicationData;

    if (ad.email) emails.push(ad.email.trim());
    if (ad.hqAddress?.email) emails.push(ad.hqAddress.email.trim());
    if (ad.hqAddress?.kep) emails.push(ad.hqAddress.kep.trim());
    if (ad.branchOfficeAddress?.email?.trim())
      emails.push(ad.branchOfficeAddress.email.trim());
    if (ad.branchOfficeAddress?.kep_.trim()) emails.push(ad.branchOfficeAddress.kep.trim());

    if (ad.contacts) {
      for (let i = 0; i < ad.contacts.length; i++) {
        const contactEmail = ad.contacts[i]?.email?.trim();
        if (contactEmail) emails.push(contactEmail);
      }
    }
    emails = Array.from(new Set(emails)).map((eml) => {
      return {
        email: eml,
        sirkuler: true,
        notifications: true,
        readOnly: true,
        isValid: true
      };
    });

    if (ad.notificationSettings && ad.notificationSettings.length > 0) {
      for (let i = 0; i < ad.notificationSettings.length; i++) {
        const eml = ad.notificationSettings[i];
        const emlFromForm = emails.length >= i ? emails[i] : null;

        if (emlFromForm && emlFromForm.email == eml.email) {
          emails[i].sirkuler = eml.sirkuler;
        } else {
          emails.push(eml);
        }
      }
    }

    this.emails = [...new Set(emails)];

    this.updateNotificationSettings();
  }
};
</script>

<style lang="scss">
.table-clean {
  width: 100%;

  th,
  td {
    border: none;
    padding: 0;

    &.c-sirkuler {
      width: 170px;
      padding: 0 20px;
    }
  }

  thead {
    th {
      border: none;
      font-weight: normal;
      color: #000;
    }
  }
}

.p-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

td.c-remove {
  width: 90px;
  .btn {
    width: 100%;
  }
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  border-color: #eee;
  color: #ddd;
}
</style>
